<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >Configuración ochoPay de {{ country.name }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
              <v-select
                item-text="name"
                item-value=".key"
                :items="cities"
                label="Seleccionar ciudad"
                class="mt-2"
                height="50"
                rounded
                v-model="selectedCity"
                @change="getButtons"
                hide-details
                outlined
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-row justify="end">
                <v-btn
                  color="primary"
                  class="ma-2 white--text mr-5"
                  @click="sortDialog = true"
                  :loading="loading"
                  :disabled="items.length < 2"
                >
                  <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                  Ordenar
                </v-btn>

                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  :loading="loading"
                  @click="addButton"
                  :disabled="!selectedCity"
                >
                  <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                  Agregar botón
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="5"
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [5],
            }"
          >
            <!-- slot active -->
            <template v-slot:[`item.active`]="{ item }">
              <v-switch
                @change="
                  switchControlChanged(
                    { active: item.active },
                    item['.key'],
                    item
                  )
                "
                v-model="item.active"
                :loading="loading"
              ></v-switch>
            </template>

            <template v-slot:[`item.enabled`]="{ item }">
              <v-switch
                @change="
                  switchControlChanged(
                    { enabled: item.enabled },
                    item['.key'],
                    item
                  )
                "
                v-model="item.enabled"
                :loading="loading"
              ></v-switch>
            </template>

            <template v-slot:[`item.showInLobby`]="{ item }">
              <v-switch
                @change="
                  switchControlChanged(
                    { showInLobby: item.showInLobby },
                    item['.key'],
                    item
                  )
                "
                v-model="item.showInLobby"
                :loading="loading"
              ></v-switch>
            </template>

            <template v-slot:[`item.targetType`]="{ item }">
              {{ item.targetType | filterTargetType }}
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="editButton(item)" small color="primary"
                >Editar</v-btn
              >

              <v-btn
                class="ml-5 white--text"
                @click="
                  selectedItem = item;
                  deleteDialog = true;
                "
                small
                color="red darken-4"
                >Eliminar</v-btn
              >
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-if="addDialog" v-model="addDialog" max-width="800px">
      <add
        @cancel="addDialog = false"
        :cityId="selectedCity"
        :country="country"
        :cities="cities"
        :selectedItem="selectedItem"
        @success="handleSuccess"
        :position="items.length"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-if="sortDialog"
      v-model="sortDialog"
      max-width="450px"
    >
      <sort
        @update="handleUpdatePosition"
        @cancel="sortDialog = false"
        :loading="loading"
        :items="items"
      />
    </v-dialog>

    <v-dialog max-width="450" v-model="deleteDialog" v-if="deleteDialog">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="deleteDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ selectedItem.name }}</span
          >?
        </v-card-text>
        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            height="50"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
// import moment from "moment-timezone";
// import "moment/locale/es";
// moment.locale("es");
import sort from "./sort.vue";
import add from "./add-button.vue";
import { mapState } from "vuex";

export default {
  name: "configure-ocho-pay",
  props: ["country"],
  components: {
    add,
    sort,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      items: [],
      search: "",
      cities: [],
      selectedCity: "",
      deleteDialog: false,
      sortDialog: false,
      headers: [
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        { text: "Tipo", value: "targetType" },
        { text: "Habilitado", value: "enabled" },
        { text: "Activo", value: "active" },
        { text: "Mostrar en Lobby", value: "showInLobby" },
        { value: "options", align: "end", width: "350px" },
      ],

      addDialog: false,
      selectedItem: null,
    };
  },

  filters: {
    filterTargetType(e) {
      let types = {
        category: "Categorías",
        business: "Comercio",
        product: "Producto",
        url: "URL",
      };

      return e ? types[e] : "Desconocido";
    },
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    addButton() {
      this.addDialog = true;
      this.selectedItem = null;
    },

    editButton(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },

    handleSuccess() {
      this.snackbarText = "Botón agregado exitosamente.";
      this.snackbar = true;
      this.addDialog = false;
    },
    async handleUpdatePosition(e) {
      this.loading = true;
      for (let i = 0; i < e.length; i++) {
        const item = e[i];

        await db
          .collection("ochoPay")
          .doc(this.country[".key"])
          .collection("buttons")
          .doc(item[".key"])
          .update({
            position: i + 1,
          });

        this.loading = false;
        this.snackbarText = "Información actualizada correctamente.";
        this.snackbar = true;
        this.sortDialog = false;
      }
    },

    confirmDelete() {
      this.loading = true;
      db.collection("ochoPay")
        .doc(this.country[".key"])
        .collection("buttons")
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.user[".key"],
        })
        .then((ref) => {
          this.loading = false;
          this.snackbarText = "Información actualizada correctamente.";
          this.snackbar = true;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.loading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    async getButtons(e) {
      this.loading = true;
      await this.$binding(
        "items",
        db
          .collection("ochoPay")
          .doc(this.country[".key"])
          .collection("buttons")
          .where("deleted", "==", false)
          .where("cities", "array-contains", e)
      );
      this.loading = false;
    },
    switchControlChanged(data, buttonId, button) {
      if (data && buttonId) {
        this.loading = true;

        db.collection(`ochoPay/${this.country[".key"]}/buttons`)
          .doc(buttonId)
          .update(data)
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            // button.active = !button.active;
          });
      }
    },
  },

  async mounted() {
    await this.$binding(
      "cities",
      db
        .collection("cities")
        .where("countryId", "==", this.country.countryId)
        .where("deleted", "==", false)
        .orderBy("name")
    );
    this.loading = false;
  },
};
</script>
