<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedItem ? "Editar" : "Agregar" }} botón</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          :loading="loading"
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <img
                    @click="showFileChooser"
                    class="image"
                    :src="defaultImage"
                    alt=""
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-col cols="12" sm="12" md="12">
                    <p>Nombre</p>
                    <input
                      class="control-input"
                      type="text"
                      :class="{ errorC: validated && !button.name }"
                      placeholder="Ingrese el nombre"
                      v-model="button.name"
                    />
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <p>Color</p>
                    <input
                      class="category-color"
                      type="color"
                      placeholder="Color de fondo"
                      v-model="button.color"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Seleccionar ciudades</p>
              <v-select
                v-model="button.cities"
                multiple
                rounded
                :loading="loading"
                outlined
                hide-details
                :class="{ errorC: validated && !button.cities.length }"
                item-value=".key"
                item-text="name"
                :items="cities"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p>Seleccionar tipo de botón</p>
              <v-autocomplete
                v-model="button.targetType"
                rounded
                hide-details
                outlined
                @change="getData"
                item-value="value"
                item-text="name"
                :class="{ errorC: validated && !button.targetType }"
                :items="types"
                :loading="loading"
              />
            </v-col>

            <v-col
              v-if="button.targetType && button.targetType != 'url'"
              cols="12"
              sm="12"
              md="12"
            >
              <p>Seleccionar categoría</p>
              <v-autocomplete
                v-model="selectedCategory"
                rounded
                hide-details
                outlined
                item-value=".key"
                item-text="name"
                @change="getBusinesses"
                :class="{ errorC: validated && !selectedCategory }"
                :items="categories"
                :loading="loading"
              />
            </v-col>

            <v-col
              v-if="
                button.targetType == 'business' ||
                button.targetType == 'product'
              "
              cols="12"
              sm="12"
              md="12"
            >
              <p>Seleccionar comercio</p>
              <v-autocomplete
                v-model="selectedBusiness"
                rounded
                hide-details
                outlined
                :loading="loading"
                item-value=".key"
                item-text="shortName"
                @change="getProducts"
                :class="{ errorC: validated && !selectedBusiness }"
                :items="businesses"
              />
            </v-col>

            <v-col
              v-if="button.targetType == 'product'"
              cols="12"
              sm="12"
              md="12"
            >
              <p>Seleccionar producto</p>
              <v-autocomplete
                v-model="selectedProduct"
                rounded
                hide-details
                :loading="loading"
                outlined
                item-value=".key"
                item-text="name"
                :items="products"
                :class="{ errorC: validated && !selectedProduct }"
              />
            </v-col>

            <v-col v-if="button.targetType == 'url'" cols="12" sm="12" md="12">
              <p>URL</p>
              <input
                class="control-input"
                type="text"
                placeholder="Ingrese la URL"
                v-model="button.url"
                :class="{ errorC: validated && !button.url }"
              />
            </v-col>

            <v-col cols="4" sm="4" md="4">
              <v-switch
                v-model="button.biometricsRequired"
                :loading="loading"
                label="Datos biométricos requeridos"
              ></v-switch>
            </v-col>

            <v-col cols="4" sm="4" md="4">
              <v-switch
                :loading="loading"
                v-model="button.enabled"
                label="Habilitado"
              ></v-switch>
            </v-col>

            <v-col cols="4" sm="4" md="4">
              <v-switch
                v-model="button.isPerCity"
                :loading="loading"
                label="Por ciudad"
              ></v-switch>
            </v-col>

            <v-col cols="4" sm="4" md="4">
              <v-switch
                v-model="button.kycRequired"
                :loading="loading"
                label="KYC requerido"
              ></v-switch>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-switch
                v-model="button.showInLobby"
                :loading="loading"
                label="Mostrar en Lobby"
              ></v-switch>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-switch
                v-model="button.showInWallet"
                :loading="loading"
                label="Mostrar en Wallet"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="button.active"
                  :loading="loading"
                  label="Botón activo"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="selectedItem ? update() : save()"
                  :loading="loading"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="imageChanged"
    />
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
// import moment from "moment-timezone";
// import "moment/locale/es";
// moment.locale("es");
import { mapState } from "vuex";

export default {
  name: "edit-button",
  props: ["country", "cityId", "cities", "position", "selectedItem"],
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      button: {
        name: "",
        cities: [],
        color: "#FF1744",
        imageLobby: "",
        deleted: false,
        biometricsRequired: true,
        enabled: true,
        isPerCity: true,
        showInLobby: true,
        showInWallet: true,
        kycRequired: true,
      },
      file: null,
      selectedCategory: null,
      categories: [],
      businesses: [],
      validated: false,
      selectedBusiness: null,
      products: [],
      selectedProduct: null,
      defaultImage: require("@/assets/product-default.jpg"),
      types: [
        { name: "Categoría", value: "category" },
        { name: "Producto", value: "product" },
        { name: "Comercio", value: "business" },
        { name: "URL", value: "url" },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async save() {
      try {
        this.loading = true;

        await this.validateButton();

        this.button.createdAt = new Date();
        this.button.createdBy = this.user[".key"];
        this.button.position = Number(this.position) + 1;
        this.button.categoryId = this.selectedCategory;
        this.button.businessId = this.selectedBusiness;
        this.button.productId = this.selectedProduct;

        let reference = await db
          .collection("ochoPay")
          .doc(this.country[".key"])
          .collection("buttons")
          .doc();

        if (this.file) {
          await this.uploadImage(reference.id);
        }

        reference
          .set(this.button)
          .then(() => {
            this.loading = false;
            this.$emit("success");
          })
          .catch(() => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado al crear el botón, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
        this.validated = true;
        this.loading = false;
      }
    },

    async update() {
      try {
        this.loading = true;
        await this.validateButton();

        let payload = {
          categoryId: this.selectedCategory || "",
          businessId: this.selectedBusiness || "",
          productId: this.selectedProduct || "",
          active: this.button.active,
          cities: this.button.cities,
          color: this.button.color || "",
          modifiedAt: new Date(),
          modifiedBy: this.user[".key"],
          description: this.button.description || "",
          biometricsRequired: this.button.biometricsRequired || false,
          enabled: this.button.enabled || false,
          isPerCity: this.button.isPerCity || false,
          showInLobby: this.button.showInLobby || false,
          showInWallet: this.button.showInWallet || false,
          kycRequired: this.button.kycRequired || false,
          name: this.button.name,
          section: "",
          size: "",
          targetType: this.button.targetType,
          url: this.button.url || "",
        };

        if (this.file) {
          await this.uploadImage(this.selectedItem[".key"]);
        }

        await db
          .collection("ochoPay")
          .doc(this.country[".key"])
          .collection("buttons")
          .doc(this.selectedItem[".key"])
          .update(payload)
          .then(() => {
            this.loading = false;
            this.$emit("success");
          })
          .catch(() => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado al crear el botón, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } catch (error) {
        this.snackbarText = error;
        this.snackbar = true;
        this.validated = true;
        this.loading = false;
      }
    },

    uploadImage(buttonId) {
      return new Promise((resolve, reject) => {
        let extension = this.file.type.split("/")[1];

        fb.app()
          .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-pay`)
          .ref()
          .child(`${this.country[".key"]}/${buttonId}/preview.${extension}`)
          .put(this.file)
          .then(() => {
            return resolve("success");
          })
          .catch(() => {
            return reject(
              "Ocurrió un error al cargar la imágen, inténtelo nuevamente."
            );
          });
      });
    },

    validateButton() {
      return new Promise((resolve, reject) => {
        if (!this.button.name) {
          return reject("Ingrese el nombre del botón.");
        }

        if (!this.button.cities || !this.button.cities.length) {
          return reject("Selecciones las ciudades donde se mostrará el botón.");
        }

        if (!this.button.targetType) {
          return reject("Selecciones el tipo de botón.");
        }

        switch (this.button.targetType) {
          case "url":
            if (!this.button.url) {
              return reject("Ingrese la URL del botón.");
            }
            break;

          case "category":
            if (!this.selectedCategory) {
              return reject("Seleccione la categoría.");
            }
            break;

          case "business":
            if (!this.selectedCategory) {
              return reject("Seleccione la categoría.");
            }

            if (!this.selectedBusiness) {
              return reject("Seleccione el comerico.");
            }
            break;

          case "product":
            if (!this.selectedCategory) {
              return reject("Seleccione la categoría.");
            }

            if (!this.selectedBusiness) {
              return reject("Seleccione el comerico.");
            }

            if (!this.selectedProduct) {
              return reject("Seleccione el producto.");
            }
            break;

          default:
            break;
        }

        return resolve("success");
      });
    },

    async getData() {
      this.loading = true;
      this.businesses = [];
      this.products = [];
      this.categories = [];
      this.selectedCategory = "";
      this.selectedBusiness = "";
      this.selectedProduct = "";

      await this.$binding(
        "categories",
        db
          .collection("categories")
          .where("city", "==", this.cityId)
          .where("deleted", "==", false)
      );
      this.loading = false;
    },
    async getBusinesses(e) {
      if (
        e &&
        (this.button.targetType == "product" ||
          this.button.targetType == "business")
      ) {
        this.loading = true;
        await this.$binding(
          "businesses",
          db
            .collection("businesses")
            .where("ochoCategories", "array-contains", e)
        );
        this.loading = false;
      }
    },

    imageChanged(e) {
      if (!e.target.files[0]) return;

      this.file = e.target.files[0];

      if (this.file && this.file.type.indexOf("image/") === -1) {
        console.log("tipo de archivo no permitido");
        this.file = null;
      }

      var reader = new FileReader();
      reader.onload = (e) => {
        this.defaultImage = e.target.result;
      };

      if (this.file) {
        reader.readAsDataURL(this.file);
      }
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    async getProducts(e) {
      if (e && this.button.targetType == "product") {
        this.loading = true;
        await this.$binding(
          "products",
          db
            .collection("products")
            .where("business", "array-contains", e)
            .where("addon", "==", false)
        );

        this.loading = false;
      }
    },
  },
  async mounted() {
    if (this.selectedItem) {
      this.button = Object.assign({}, this.selectedItem);

      if (this.button.image && this.button.image.original) {
        this.defaultImage = this.button.image.original;
      }

      this.selectedCategory = this.button.categoryId;
      this.selectedBusiness = this.button.businessId;
      this.selectedProduct = this.button.productId;

      if (
        this.button.targetType == "category" ||
        this.button.targetType == "product" ||
        this.button.targetType == "business"
      ) {
        await this.$binding(
          "categories",
          db
            .collection("categories")
            .where("city", "==", this.button.cities[0])
            .where("deleted", "==", false)
        );
      }

      if (
        this.button.targetType == "product" ||
        this.button.targetType == "business"
      ) {
        this.getBusinesses(this.selectedCategory);
      }

      if (this.button.targetType == "product") {
        this.getProducts(this.selectedBusiness);
      }
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
}

.category-color::-webkit-color-swatch {
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  border-radius: 50px;
  padding: 0;
}

.image {
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  max-height: 280px;
}

.upload {
  display: none;
}

.errorC {
  border: 1px solid red;
}
</style>