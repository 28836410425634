<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Ocho Pay</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            @click="addDialog = true"
            color="primary"
            class="ma-2 white--text"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Agregar pais
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ochoPay"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.country`]="{ item }">
            {{ item.countryName }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              @click="showConfiguration(item)"
              :loading="loading"
              small
              color="primary"
              >Configurar</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-if="addDialog" v-model="addDialog" max-width="600px">
      <add-ocho-pay
        @success="ochoPayAdded"
        :ochoPay="ochoPay"
        :countries="countries"
        @cancel="addDialog = false"
      ></add-ocho-pay>
    </v-dialog>

    <v-dialog
      persistent
      v-if="configureDialog"
      v-model="configureDialog"
      max-width="1200px"
    >
      <configure
        :country="selectedItem"
        @cancel="configureDialog = false"
      ></configure>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import addOchoPay from "./active-country";
import configure from "./configure";

export default {
  name: "ocho-pay",
  components: {
    lottie: Lottie,
    addOchoPay,
    configure,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      snackbar: false,
      addDialog: false,
      configureDialog: false,
      snackbarText: "",
      ochoPay: [],
      countries: [],
      selectedItem: null,
      headers: [
        {
          text: "Código",
          value: "code",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Bandera",
          value: "flag",
        },

        {
          text: "Activo",
          value: "active",
        },
        {
          value: "options",
          align: "end",
          width: "400px",
        },
      ],
    };
  },

  watch: {
    ochoPay() {
      this.ochoPay.forEach((item) => {
        let country = this.countries.find((c) => c[".key"] == item.countryId);

        if (country) {
          item.name = country.name;
          item.flag = country.flag;
          item.code = country.code;
        }
      });
    },
  },

  methods: {
    showConfiguration(item) {
      this.selectedItem = item;
      this.configureDialog = true;
    },
    switchControlChanged(data, ochoPayId, ochoPay) {
      if (data && ochoPayId) {
        this.loading = true;
        db.collection("ochoPay")
          .doc(ochoPayId)
          .update({
            active: data.active,
          })
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            ochoPay.active = !ochoPay.active;
          });
      }
    },

    ochoPayAdded() {
      this.snackbarText = "Pais activado exitosamente.";
      this.snackbar = true;
      this.addDialog = false;
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "pais");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await db
      .collection("countries")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let country = item.data();
          country[".key"] = item.id;
          this.countries.push(country);
        });
      });

    this.$binding("ochoPay", db.collection("ochoPay"));

    this.loading = false;
  },
};
</script>
