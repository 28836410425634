<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Activar Ocho Pay</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col class="ma-0 pa-0" cols="12" sm="12" md="12">
              <v-select
                :items="sortedItems"
                item-text="name"
                item-value=".key"
                rounded
                v-model="item.countryId"
                :loading="loading"
                hide-details
                outlined
                class="mt-5"
                height="50px"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  :loading="loading"
                  v-model="item.active"
                  label="Activo"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  :loading="loading"
                  @click="add()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  name: "add-ocho-pay",
  props: ["ochoPay", "countries"],

  data() {
    return {
      snackbar: false,
      snackbarText: "",
      item: {
        active: true,
        countryId: "",
      },
      unconfiguredCountries: [],
      loading: false,
    };
  },

  computed: {
    ...mapState(["user"]),
    sortedItems() {
      function compare(a, b) {
        if (a.name && b.name && a.name < b.name) return -1;
        if (a.name && b.name && a.name > b.name) return 1;
        return 0;
      }

      return this.unconfiguredCountries.sort(compare);
    },
  },

  methods: {
    add() {
      if (this.item.countryId) {
        this.item.createdAt = new Date();
        this.item.createdBy = this.user[".key"];
        this.item.deleted = false;

        this.loading = true;

        console.log(this.item);

        db.collection("ochoPay")
          .add(this.item)
          .then((res) => {
            this.$emit("success");
          })
          .catch((e) => {
            this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Debe seleccionar el pais.";
        this.snackbar = true;
      }
    },
  },

  mounted() {
    let addedCountries = this.ochoPay.map((item) => {
      return item.countryId;
    });
    this.unconfiguredCountries = this.countries.filter(
      (item) => !addedCountries.includes(item[".key"])
    );
  },
};
</script>
