<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Ordenar</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="height-modal">
        <div
          v-if="localItems.length == 0"
          class="mt-10"
          style="text-align: center"
        >
          <span>Lista vacía 😔</span>
        </div>

        <draggable
          v-bind="dragOptions"
          @change="onChange"
          ghost-class="ghost"
          v-model="localItems"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group type="transition" name="flip-list">
            <v-list-item
              style="cursor: move"
              class="list-group-item"
              v-for="item in localItems"
              :key="item['.key']"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>fas fa-bars</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </transition-group>
        </draggable>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!changed"
          color="primary"
          block
          :loading="loading"
          class="mb-5"
          height="50px"
          @click="update()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Lottie from "../../../components/Lottie";
import draggable from "vuedraggable";

export default {
  name: "Sort",
  props: ["city", "items", "loading"],
  components: {
    lottie: Lottie,
    draggable,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      localItems: [],
      changed: false,
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    onChange() {
      this.changed = true;
    },

    sortedItems: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }

      return this.localItems.sort(compare);
    },

    update() {
      this.$emit("update", this.localItems);
    },
  },

  async mounted() {
    this.localItems = Object.assign([], this.items);
    this.localItems = this.sortedItems();
  },
};
</script>


<style scoped>
.height-modal {
  height: 50vh;
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
}

.list-group-item {
  border-bottom: 1px solid rgba(128, 128, 128, 0.425);
}

.ghost {
  opacity: 0.5;
  background: #e0b19d;
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
